@mixin font-size($font-size, $base-size) {
  @if ($base-size == null) {
    font-size: px-to-em($font-size);
  } @else {
    font-size: px-to-em($font-size, $base-size);
  }
}

/*==================================
=            Mixin Helpers             =
==================================*/

@mixin font-face-family($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path-fonts}#{$file-path}.eot");
    src: url("#{$file-path-fonts}#{$file-path}.eot?#iefix")
        format("embedded-opentype"),
      url("#{$file-path-fonts}#{$file-path}.woff") format("woff"),
      url("#{$file-path-fonts}#{$file-path}.woff2") format("woff2"),
      url("#{$file-path-fonts}#{$file-path}.ttf") format("truetype");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

/*==================================
=            Icons             =
==================================*/

@mixin icon-face-family(
  $font-family,
  $file-path,
  $font-hash: "9c3a3a",
  $font-weight: normal,
  $font-style: normal
) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}#{$font-family}.ttf?#{$font-hash}") format("truetype"),
      url("#{$file-path}#{$font-family}.woff?#{$font-hash}") format("woff"),
      url("#{$file-path}#{$font-family}.svg?#{$font-hash}##{$font-family}")
        format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
