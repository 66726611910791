/*==========================================
=            Utility and States            =
==========================================*/

/*----------  Utilities  ----------*/
.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-reset-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 0;
  }
}

.util-mobile-only {
  display: none;

  @include screen($bp-mobile) {
    display: block;
  }
}

.util-mobile-hidden {
  @include screen($bp-mobile) {
    display: none;
  }
}

.util-mobile-hidden {
  @include screen($bp-mobile) {
    display: none;
    &.hide-important {
      display: none !important;
    }
  }
}

.util-tablet-hidden {
  @include screen($bp-tablet) {
    display: none;
    &.hide-important {
      display: none !important;
    }
  }
}

.util-position-relative {
  position: relative;
}

//visually hide content but accssible to screen readers
//http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

//solve problem where VoiceOver jumps content to underneath the header
.sr-invisible-offset::before {
  display: block;
  content: " ";
  margin-top: -150px;
  height: 150px;
  visibility: hidden;
}

.centered {
  text-align: center;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none !important;
}