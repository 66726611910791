@use "sass:math";

//placeholders
%hide {
  display: none !important;
}

// Default
body {
  font-size: 10pt;

  &:before {
    content: none;
  }
}

button,
.btn {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  transition: $transition-default;
}

//general styles
.page-wrapper,
.content-wrapper {
  padding-left: 0;
  padding-right: 0;
}

//vertical spacing
.scrollspy-sections {
  .js-scrollspy-section {
    margin-bottom: $section-spacing-large;
  }
}

.related-capabilities,
.insights-grid,
.capabilities-children,
.flex-panel,
.personnel-spotlight,
.overview {
  margin-bottom: $section-spacing-large;
}

.accordion-node {
  margin-bottom: $section-spacing;
}

.rte {
  margin-bottom: $element-spacing;
}
// Header
.site-search-toggle,
.mobile-toggle,
.site-nav,
.site-header-firm-name {
  @extend %hide;
}

.site-logo {
  width: px-to-em(250px);
  display: inline-block;
  color: $color-brand-white;
  background-color: $color-brand-red;
}

.site-logo-img {
  display: block;
  width: 100%;
}

.breadcrumbs {
  padding: $section-spacing-small 0 $section-spacing;
}

.breadcrumbs-item {
  display: inline;
}

.breadcrumbs-title {
  color: $color-gray-ac;
  @include font-univers-condensed-bold();
}

.site-header-utility {
  @include font-univers-condensed-bold($font-size-site-header-utility);

  line-height: 1.2;
}

.page-header,
.page-header-flex {
  padding-bottom: $section-spacing-large;
}

.page-header-title,
.page-header-flex-title {
  @include font-univers-condensed-bold(55px);

  line-height: 1.1;
}

.page-header-flex-section-title {
  @include font-size-entity-title();
  @extend %uc;

  padding-bottom: px-to-em(13px, $font-size-entity-title);
  margin-bottom: px-to-em(22px, $font-size-entity-title);
  border-bottom: 1px solid $color-gray-alt;
}

.section-nav {
  display: inline-block;

  & + .section-subnav {
    display: inline-block;

    &:before {
      content: "/";
      color: $color-gray-steel;
      margin: 0 1em;
    }

    .section-subnav-wrapper {
      display: inline-block;
    }
  }
}

.section-nav-menu-link {
  @extend %uc;
  @include font-univers-condensed-bold(20px);
  display: none;

  .is-menu-active & {
    display: block;
  }
}

.section-nav-toggle {
  @extend %hide;
}

.section-subnav-links-title {
  @extend %uc;
  @include font-size-entity-title();
  display: none;

  .is-submenu-active & {
    display: block;
  }
}

//components
.impactful-lead {
  padding: $section-spacing 0;
}

.impactful-lead-copy {
  font-size: px-to-em($impact-lead-copy);
  line-height: 1.2;
  padding: px-to-em(22px, $impact-lead-copy) 0;
  border-top: 2px solid $border-red;
  border-bottom: 2px solid $border-red;
}

.impactful-lead-cross-links {
  @extend %hide;
}

// Articles
.article-header-title {
  @include font-size-heading();
}

.article-header-meta {
  font-size: px-to-em($font-size-h3);

  @include design-mark(17px, $font-size-h3, $color-brand-gray);
}

.article-header-slogan {
  @include font-univers-condensed-light-oblique($font-size-h3);
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
}

.article-header-authors {
  font-size: px-to-em($font-size-entity-title);
}

.article-body {
  margin-bottom: $section-spacing-large;
}

//capabilities
.capabilities-list-capability {
  display: inline;
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.4;

  &:after {
    content: " // ";
    display: inline-block;
    margin: 0 0.2em;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.profile-header {
  position: relative;
}

.profile-header-wrapper {
  height: px-to-em(400px);
  display: table;
}

.profile-header-figure,
.profile-header-details {
  display: table-cell;
  vertical-align: top;
}

.profile-header-snapshot-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: px-to-em(350px);
}

.profile-header-snapshot {
  height: px-to-em(400px);
  position: relative;
  margin-left: -3em;
}

.profile-header-details {
  position: relative;
  z-index: 5;
  padding-right: 1em;
  padding-left: 1em;
  background-color: $color-brand-white;
  height: 100%;
}

.profile-header-name {
  @include font-size-heading();
}

.profile-header-position {
  @include font-univers-condensed-light($font-size-h3);

  @include design-mark(17.5px, $font-size-h3, $color-brand-gray);
}

.profile-header-email {
  @include font-univers-condensed-light($font-size-entity-title);
}

.profile-header-linkedin {
  @extend %hide;
}

//related offices
//
.offices-related {
  margin-top: 0.5em;
  overflow: hidden;
}

.offices-related-office {
  width: px-to-em(200px);
  padding-right: 0.9em;
  padding-bottom: 1em;
  display: inline-block;
  vertical-align: top;
}

.offices-related-name {
  @include font-size-entity-title();
  line-height: 1.3;
  text-transform: uppercase;
}

.offices-related-phone {
  @include font-univers-condensed-light(20px);

  line-height: 1.3em;
  text-transform: uppercase;
}

.get-in-touch {
  .offices-related-office {
    margin-top: 0.65em;
  }
}

.get-in-touch-header {
  @include font-size-heading();

  line-height: 1.2;
}

.get-in-touch-position {
  @include font-univers-condensed-light($font-size-h3);

  line-height: 1.2;

  @include design-mark(17.5px, $font-size-h3);
}

.get-in-touch-email {
  @include font-univers-condensed-light($font-size-entity-title);
}

//Banners

.personnel-banner,
.highlight-card {
  overflow: hidden;
  display: block;

  & + & {
    margin-top: $section-spacing;
  }
}

.personnel-banner-figure,
.highlight-card-figure {
  width: px-to-em(180px);
  float: left;
}

.personnel-banner-img,
.highlight-card-img {
  max-width: 100%;
}
.personnel-banner-info,
.highlight-card-link {
  margin-left: px-to-em(200px);
  display: block;
}

.personnel-banner-name {
  @include font-size-banner();
}

.personnel-banner-subtitle {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
}

.personnel-banner-details {
  @include design-mark();

  &:after {
    @include transition(border-color);
  }
}

.personnel-spotlight-view-more {
  @extend %hide;
}

.highlight-card-title {
  @include font-size-banner();

  padding-bottom: px-to-em($element-spacing-px, $font-size-banner);

  //red mark
  @include design-mark(
    $spacing: math.div($element-spacing-px, 2),
    $font-size: $font-size-banner,
    $placement: before
  );

  &:before {
    position: absolute;
    bottom: 0;
  }
}

//Related Modules

.read-more-toggle,
.overview-cta {
  @extend %hide;
}

.accordion-node-title {
  font-size: 1em;
}

.accordion-node-trigger {
  @include font-size-banner();

  text-align: left;
}

.accordion-node-content {
  display: block !important;
}

//listing articles
$listing-articles-video-padding: 40px;
$listing-articles-video-padding-mobile: 30px;
$listing-articles-video-icon: 26px;

$listing-articles-alumni-year-padding: 61px;
$listing-articles-alumni-year-dimen: 48px;
$listing-articles-alumni-year-font-size: 26px;

$listing-articles-spotlight-max-width: 175px;

.listing-articles {
  & + & {
    margin-top: $section-spacing-large;
  }

  .ng-scope {
    display: block;
  }

  //takes care of spacing
  .ng-scope {
    & + .ng-scope {
      padding-top: px-to-em($listing-padding);
    }
  }
}

.listing-articles-heading {
  $font-size: 35px;

  @include font-univers-condensed-light($font-size);
  line-height: 1.1em;

  @include design-mark(14px, $font-size, $color-brand-gray);
}

.listing-articles-item,
.insights-grid-list-item {
  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($listing-padding);
  border-bottom: $border-dark;

  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-articles-item--video {
  padding-left: px-to-em($listing-articles-video-padding);
}

.listing-articles-title-nolink {
  @include font-size-banner();

  display: block;
}

.listing-articles-title,
.insight-tile {
  @include font-size-banner();

  display: block;
}

.listing-articles-item--video {
  .listing-articles-title {
    @include ico-icon-before($icon-video) {
      position: absolute;
      font-size: px-to-em($listing-articles-video-icon, $font-size-banner);
      left: 0;
      margin-top: 0.2em;
    }
  }
}

.listing-articles-meta {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: px-to-em(
    math.div($section-spacing-small-px, 2),
    $font-size-entity-title
  );
  color: $color-gray-steel;

  .meta-category + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }

  .meta-location + .meta-date {
    &:before {
      content: "//";
      display: inline-block;
      margin-right: 0.5em;
      margin-left: 0.25em;
    }
  }
}

.listing-articles-abstract {
  margin-top: px-to-em(math.div($section-spacing-small-px, 2), $font-size-copy);

  & + .listing-articles-meta {
    margin-top: px-to-em($section-spacing-small-px, $font-size-entity-title);
  }
}

.listing-articles-figure {
  float: left;
  max-width: px-to-em(330px);
  margin-right: 1em;

  > img {
    max-width: 100%;
  }
}

.listing-articles-title-label {
  display: table;
  font-size: inherit;
}

.listing-articles-see-all {
  @extend %hide;
}

//alumni specific options
.listing-articles-item--alumni-move {
  padding-left: px-to-em($listing-articles-alumni-year-padding);
}

.listing-articles-alumni-year {
  @include transition(background-color);

  position: absolute;
  left: 0;
  background: $color-brand-red;
  font-size: px-to-em(
    $listing-articles-alumni-year-font-size,
    $font-size-banner
  );
  line-height: 1;
  padding-top: 0.45em;
  padding-right: 0.2em; //accounts for apostrophe and visual centering
  width: px-to-em(
    $listing-articles-alumni-year-dimen,
    $listing-articles-alumni-year-font-size
  );
  height: px-to-em(
    $listing-articles-alumni-year-dimen,
    $listing-articles-alumni-year-font-size
  );
  text-align: center;
  display: inline-block;
  color: $color-brand-white;
  margin-top: 0.25em;

  &:before {
    content: "’";
  }
}

.listing-articles-alumni-facts {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
  margin-top: px-to-em(
    math.div($section-spacing-small-px, 2),
    $font-size-entity-title
  );
  display: table;

  a {
    color: $color-text-red;

    &:hover {
      color: $color-link-hover;
    }

    &:focus {
      outline: 1px dotted $color-brand-red;
    }
  }

  .meta-facts {
    color: $color-gray-steel;

    & + .meta-summary {
      &:before {
        content: "-";
        display: inline-block;
        margin-right: 0.25em;
        margin-left: 0.25em;
      }
    }
  }

  .meta-summary {
    display: inline;
  }
}

.listing-articles-item--alumni-spotlight {
  .listing-articles-figure {
    max-width: px-to-em(
      $listing-articles-spotlight-max-width,
      $font-size-banner
    );
  }
}

.listing-articles-subscribe {
  @extend %hide;
}

//professional card
$professional-card-height: px-to-em(162px);
$professional-card-img-width: px-to-em(136px);

.professional-card {
  display: table;
  width: 100%;
  min-height: $professional-card-height;
}

.professional-card-figure,
.professional-card-details {
  display: table-cell;
  vertical-align: top;
}

.professional-card-figure {
  position: relative;
  overflow: hidden;
  width: $professional-card-img-width;
  max-width: $professional-card-img-width;

  > img {
    @include center(horizontal, relative);
    display: block;
    min-height: 100%;
  }
}

.professional-card-name {
  @include font-size-entity-title();
}

.professional-card-details {
  padding: 1.2em 1.5em 0.4em;
  text-align: left;

  @include design-mark(17.5px);

  &:after {
    padding-top: 0;
  }
}
.professional-card-position {
  font-size: px-to-em($font-size-entity-title);
  line-height: 1.2;
}

//related capababilities
.related-listing-capabilities-link {
  @include font-size-entity-title();

  font-size: px-to-em(
    22px
  ); //increasing font size to comply with aria requirements
  line-height: 1.2;
  display: block;
  margin-bottom: 1em;
}

//listing files
.listing-files-option {
  display: inline;
}

.listing-files-title {
  @include font-size-entity-title();

  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  border: 1px solid $color-gray-steel;
}

//credentials
.credential-item-heading {
  $font-size: 24px;

  @include font-univers-condensed-bold($font-size);

  line-height: 1.1em;

  @include design-mark(15px, $font-size, $color-brand-gray);

  &:after {
    margin-top: 0.65em;
  }
}

//Removing since it doesnt represent relavent content to the page
.twitter-feed,
.social-links,
.back-to-top-trigger,
.suggested-insight,
.languages,
.scrollspy-nav,
.read-more-toggle,
.capabilities-children-related-entit,
.btn-text-load-more,
.sr-only,
.call-to-action,
.job-body-related-video {
  @extend %hide;
}

//foundation site
.foundation-explore {
  @extend %hide;
}

.site-header--fellowship {
  margin-bottom: $section-spacing;

  .site-header-utility,
  .site-user-login-label {
    @extend %hide;
  }

  .site-logo {
    width: px-to-em(300px);
    padding: 1.25em 1.375em;
  }
}

.page-header--fellowship {
  background-image: none !important;
}

.page-header-user-actions {
  @extend %hide;
}

.article-header-condensed-details {
  @include design-mark($border-color: $color-brand-gray);
}

//article
.article-header-condensed-title {
  @extend .section-heading;
  margin-bottom: 0;
}

.article-header-condensed-meta {
  font-size: px-to-em($font-size-h3);
  margin-top: $element-spacing-small;
}

.content-lead-copy {
  font-size: px-to-em($impact-lead-copy);
  line-height: 1.2;
  padding-bottom: px-to-em(39px, $impact-lead-copy);
  border-bottom: $border-dark;
  margin-bottom: px-to-em(39px, $impact-lead-copy);
}

//Search From Controls
.keyword-search,
.alpha-search,
.exposed-search-filters,
.advanced-search,
.loading-screen,
.applied-filter-clear {
  @extend %hide;
}

//profile detail
.page-foundation-profile-detail {
}

.page-content-wrapper {
  padding: 0;
}

.profile-header-compact {
  overflow: hidden;
}

.profile-header-compact-name {
  @extend .section-heading;
}

.profile-header-compact-position {
  @include font-univers-condensed-light($font-size-subheading);

  @include design-mark(17.5px, $font-size-subheading, $color-gray-steel);

  line-break: 1.2;
}

.profile-header-compact-employment {
  @include font-univers-condensed-light($font-size-entity-title);
  line-break: 1.2;
}

.profile-header-compact-figure {
  float: left;

  & + .profile-header-compact-details {
    margin-left: px-to-em(230px);
  }
}

.profile-header-compact--fellows {
  margin-bottom: $section-spacing-large;

  .profile-header-compact-name {
    @include font-size-banner();
  }
  .profile-header-compact-position {
    font-size: $font-size-entity-title;

    &:after {
      padding-top: px-to-em(17.5px, $font-size-entity-title);
      margin-top: px-to-em(17.5px, $font-size-entity-title);
      width: px-to-em($global-line-mark, $font-size-entity-title);
    }
  }
}

.page-foundation-forum-detail,
.page-foundation-webinar-detail,
.page-foundation-facts-law-schools,
.page-foundation-facts-sponsor-orgs,
.page-foundation-facts-governance,
.page-foundation-generic-content {
  .section-nav-menu {
    @extend %hide;
  }
}

.job-header-compact {
  @include design-mark(17px, $border-color: $color-brand-gray);
}

.job-header-compact-meta {
  font-size: px-to-em($font-size-h3);

  .meta-item {
    & + .meta-item {
      &:before {
        content: "//";
        display: inline-block;
        margin: 0 0.25em;
      }
    }
  }
}

.job-header-compact-related-item {
  font-size: px-to-em($font-size-entity-title);
}

.job-header-compact-related-label {
  color: $color-gray-steel;

  &:after {
    content: ":";
    margin-right: 0.5em;
  }
}

.listing-general-entity-item {
  @include clearfix;

  position: relative;
  padding-bottom: px-to-em($listing-padding);
  border-bottom: $border-dark;

  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-general-entity-title {
  @include font-size-entity-title();

  display: block;
}

.sponsoring-organizations-filters {
  @extend %hide;
}

.listing-organization-state {
  position: relative;
  padding-bottom: px-to-em($listing-padding);

  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-organization-state-title {
  @extend .section-heading;
}

.listing-organization-city {
  & + & {
    padding-top: px-to-em($listing-padding);
  }
}

.listing-organization-city-title {
  $font-size: 35px;

  @include font-univers-condensed-light($font-size);

  line-height: 1.1em;

  color: $color-gray-steel;

  @include design-mark(14px, $font-size, $color-brand-gray);
}

.listing-organization-sponsors-list {
  font-family: $font-family-copy;
  font-size: px-to-em($font-size-copy);
}

.listing-organization-sponsor-img {
  max-height: px-to-em(23px);
  margin-right: 0.5em;
  vertical-align: middle;
}

.listing-organization-sponsor {
  position: relative;
  padding-left: 1.15em;

  &:before {
    content: "■";
    color: $color-link;
    font-size: 0.6em;
    line-height: 2.25;
    margin-left: -1.75em;
    position: absolute;
  }
}

.governance-listing {
  margin-bottom: $section-spacing-large;
}

.governance-listing-cards {
  .professional-card {
    margin-bottom: px-to-em($listing-padding);
  }
}

.category-tabs-label {
  @extend %hide;
}

.category-tabs-btn {
  @extend %uc;
  @include font-univers-condensed-bold($font-size-entity-title);

  margin-bottom: $section-spacing-small;
  display: block;
}

.category-tabs-item {
  display: none;

  &.is-tab-active {
    display: block;
  }
}

//will resort to flex box instead of using display table  in the situation where an image is not present
.fellows-card {
  @include clearfix;
  position: relative;
  margin-bottom: $section-spacing;
}

/*----------  name and partner related info  ----------*/

.fellows-card-figure {
  float: left;
  width: px-to-em($professional-thumbnail-width + 50px);
  position: relative;
  margin-right: 5%;
  overflow: hidden;

  img {
    @include center(horizontal, relative);

    display: block;
  }

  & + .fellows-card-details {
    display: table;
  }
}

.fellows-card-name {
  @include font-size-banner();

  padding-right: 2em;
}

.fellows-card-class-year {
  display: inline-block;
  font-size: px-to-em(17px, $font-size-banner);
  padding: 0.25em 0.5em 0.18em;
}

.fellows-card-description {
  @include design-mark($placement: before);
}

.fellows-card-credentials-item {
  @include font-univers-condensed-light($font-size-entity-title);

  line-height: 1.2;
  margin-top: 0.3em;
}

// Footer
.site-search-panel {
  @extend %hide;
}

.site-footer {
  @extend %hide;
}
