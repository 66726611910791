$rte-field-label-width: 260px;

.field-mapping {
  @include font-size-copy-text;
}

.field-mapping-label {
  float: left;
  width: px-to-em($rte-field-label-width, $font-size-copy);
  display: inline-block;
  font-weight: bold;

  &:after {
    content: ":";
  }
}

.field-mapping-info {
  display: table;
  margin-left: 0;
  margin-bottom: px-to-em($element-spacing-small-px, $font-size-copy);

  a {
    color: $color-link;

    &:hover {
      color: $color-link-hover;
    }
  }

  &:after {
    content: "\A";
    white-space: pre;
  }
}

@include screen($bp-mobile-landscape) {
  .field-mapping-label {
    float: none;
    width: auto;
    display: block;
  }

  .rte-field-listing-info {
    display: block;
  }
}
