$rte-field-label-width: 300px;

.rte {
  $blockquote-size: 28px;
  $highlight-link-size: 28px;

  @include font-size-copy-text;

  strong,
  b {
    font-family: inherit;
    font-weight: bold;
  }

  em,
  i {
    font-family: inherit;
    font-style: italic;
  }

  a {
    color: $color-red-on-white-foreground;

    &:hover {
      color: $color-link-hover;
    }
  }

  ul,
  ol,
  p,
  img,
  blockquote,
  address {
    margin-bottom: px-to-em($element-spacing-px, $font-size-copy);

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    height: auto;
    margin-top: px-to-em($element-spacing-px, $font-size-copy);
    max-width: 100%;
  }

  ul,
  ol {
    padding-left: 1.15em;
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        content: "■";
        color: $color-brand-red;
        font-size: 0.6em;
        line-height: 2.25;
        margin-left: -1.75em;
        position: absolute;
        speak: none;
      }
    }

    ul {
      li {
        &:before {
          color: $color-text-gray-soft;
        }
      }
    }
  }

  li {
    margin-bottom: px-to-em($element-spacing-small-px, $font-size-copy);
  }

  ol {
    list-style-type: decimal;
  }

  .list-condensed {
    $spacing-condensed: 9px;

    padding-left: 0;

    li {
      margin-bottom: px-to-em($spacing-condensed, $font-size-copy);

      &:before {
        content: none;
      }
    }
  }

  h2 {
    @include font-univers-condensed-light($font-size-h2, $font-size-copy);
    font-size: 2em;
    margin-bottom: px-to-em($element-spacing-px, $font-size-h2);
    margin-top: px-to-em($section-spacing-px + 6, $font-size-h2);

    &:first-child {
      margin-top: 0;
    }
  }

  .highlight-link,
  .rte-call-to-action {
    $spacing: $section-spacing-small-px;

    @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

    line-height: 1.2;
    padding: px-to-em($spacing, $blockquote-size) 0;
    padding-right: $arrow-padding;
    border: $border-dark;
    border-width: 1px 0;
    margin-left: 0;
    position: relative;

    a {
      color: $color-brand-black;
      display: block;

      @include ico-icon-after($icon-chevron-right) {
        @include center(vertical);

        font-size: px-to-em($arrow-icon-size, $blockquote-size);
        right: px-to-em($arrow-listing-offset, $arrow-icon-size);
        color: $color-link;

        transition: color $transition-timing $transition-ease,
          transform $transition-timing $transition-ease;
      }

      &:hover {
        color: $color-link-hover;

        &:after {
          color: $color-link-hover;
          transform: translateX(100%) translateY(-50%);
        }
      }
    }
  }

  h3 {
    @include font-univers-condensed-bold($font-size-h3, $font-size-copy);

    margin-bottom: px-to-em($element-spacing-px, $font-size-h3);
  }

  blockquote,
  .blockquote {
    @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

    line-height: 1.2;
    margin: px-to-em($element-spacing-px, $blockquote-size) 0;
    margin-left: 1.5em;
    position: relative;

    @include ico-icon-before($icon-quotation-mark) {
      position: absolute;
      right: 100%;
      margin-right: 0.4em;
      color: $color-brand-red;
    }
  }

  .blockquote-borders {
    $spacing: $section-spacing-small-px;

    @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

    line-height: 1.2;

    padding: px-to-em($spacing, $blockquote-size) 0;
    border: $border-dark;
    border-width: 1px 0;

    &:before {
      display: none;
    }
  }

  .subtitle-with-border {
    display: block;
    @include design-mark(13px, $font-size-copy, $color-gray-steel);
  }

  .video-embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: px-to-em(560px);

    iframe,
    embed,
    object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  iframe,
  embed,
  object {
    max-width: 100% !important;
  }
}

.rte {
  .rte-field-listing {
    padding-left: 0;
  }

  .rte-basic-list {
    padding-left: 0;

    li {
      margin-bottom: 0.2em;

      &:before {
        display: none;
      }
    }
  }

  .rte-copy-size {
    font-size: 1em;
    line-height: $font-line-height;
    font-family: $font-family-copy;
  }
}

.rte-field-listing {
  padding-left: 0;

  .rte-field-listing-item {
    margin-bottom: px-to-em($element-spacing-small-px, $font-size-copy);
    color: $color-text-body;

    //hide bullet
    &:before {
      display: none;
    }
  }
}

.rte-field-listing-label {
  float: left;
  width: px-to-em($rte-field-label-width, $font-size-copy);
  display: inline-block;

  &:after {
    content: ":";
  }
}

.rte-field-listing-info {
  display: table;
}

.rte .rte-contact-info {
  padding-left: 0;

  >li {
    color: $color-text-body;
    margin-bottom: 0.25em;

    &:before {
      //hide bullet
      display: none;
    }
  }
}

@include screen($bp-scrollspy) {
  .rte {
    .blockquote-borders {
      margin-left: 0;
    }
  }
}

@include screen($bp-mobile) {
  .rte {
    font-size: px-to-em($font-size-copy-mobile);
  }
}

@include screen($bp-mobile-landscape) {
  .rte-field-listing-label {
    float: none;
    width: auto;
    display: block;
  }

  .rte-field-listing-info {
    display: block;
  }

  .rte {
    a {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

.rte-blockquote {
  $blockquote-size: 28px;

  @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

  line-height: 1.2;
  margin: px-to-em($element-spacing-px, $blockquote-size) 0;
  margin-left: 1.5em;
  position: relative;

  @include ico-icon-before($icon-quotation-mark) {
    position: absolute;
    right: 100%;
    margin-right: 0.4em;
    color: $color-brand-red;
  }
}

.rte-call-to-action {
  $blockquote-size: 28px;
  $spacing: $section-spacing-small-px;

  @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

  line-height: 1.2;
  padding: px-to-em($spacing, $blockquote-size) 0;
  padding-right: $arrow-padding;
  border: $border-dark;
  border-width: 1px 0;
  margin-left: 0;
  position: relative;
  display: block;

  a {
    color: $color-brand-black;
    display: block;

    @include ico-icon-after($icon-chevron-right) {
      @include center(vertical);

      font-size: px-to-em($arrow-icon-size, $blockquote-size);
      right: px-to-em($arrow-listing-offset, $arrow-icon-size);
      color: $color-link;

      transition: color $transition-timing $transition-ease,
        transform $transition-timing $transition-ease;
    }

    &:hover {
      color: $color-link-hover;

      &:after {
        color: $color-link-hover;
        transform: translateX(100%) translateY(-50%);
      }
    }
  }
}

a.rte-call-to-action {
  $blockquote-size: 28px;
  $spacing: $section-spacing-small-px;

  @include font-univers-condensed-bold($blockquote-size, $font-size-copy);

  line-height: 1.2;
  padding: px-to-em($spacing, $blockquote-size) 0;
  padding-right: $arrow-padding;
  border: $border-dark;
  border-width: 1px 0;
  margin-left: 0;
  position: relative;
  display: block;
  color: $color-brand-black;
  display: block;

  @include ico-icon-after($icon-chevron-right) {
    @include center(vertical);

    font-size: px-to-em($arrow-icon-size, $blockquote-size);
    right: px-to-em($arrow-listing-offset, $arrow-icon-size);
    color: $color-link;

    transition: color $transition-timing $transition-ease,
      transform $transition-timing $transition-ease;
  }

  &:hover {
    color: $color-link-hover;

    &:after {
      color: $color-link-hover;
      transform: translateX(100%) translateY(-50%);
    }
  }
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.rte {
  --table-border: #cbcbcb;
  --table-subtitle-bg: #cbcbcb;
  --table-header-bg: #1e1e1d;
  --table-bg: #fff;
  --table-alternate-row: #ededed;


  .oni-table--dark,
  .oni-table-two-column {
    --table-border: #fff;
    --table-header-bg: #1e1e1d;
    --table-bg: #ededed;
  }

  .oni-table--blue {
    --table-border: #fff;
    --table-header-bg: #124059;
    --table-bg: #ededed;
  }

  .hide-cell {
    display: none;
  }

  .oni-table,
  .oni-contacts-table {
    margin: 50px 0 20px;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid var(--table-border);
    border-color: var(--table-border);
    background-color: var(--table-bg);
    width: 100%;
    border-spacing: 0;

    th {
      color: #fff;
      background-color: var(--table-header-bg);
      padding: 12px 15px;
      font-size: 15px;
      border: 1px solid var(--table-border);
    }

    td {
      padding: 12px 15px;
      border: 1px solid var(--table-border);
      font-size: 14px;
    }

    td[colspan] {
      background-color: var(--table-subtitle-bg);
    }
  }

  .oni-table-two-column {
    margin: 50px 0 20px;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid var(--table-border);
    border-color: var(--table-border);
    background-color: var(--table-bg);
    width: 100%;
    border-spacing: 0;
    th {
      color: #fff;
      background-color: var(--table-header-bg);
      padding: 12px 15px;
      font-size: 15px;
      border: 1px solid var(--table-border);
    }
    td {
      padding: 12px 15px;
      border: 1px solid var(--table-border);
      font-size: 14px;
    }
    td[colspan] {
      background-color: var(--table-subtitle-bg);
    }
  }

  @include screen($bp-tablet) {
    .oni-table,
    .oni-contacts-table {
      background-color: #fff;
      border: 1px solid var(--table-alternate-row);

      thead {
        display: none;
      }

      tbody {
        display: flex;
        flex-wrap: wrap;
      }

      tbody tr {
        &:nth-child(2n) {
          background-color: var(--table-alternate-row);
        }
      }

      tbody tr {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
      }

      // Show Headers
      td {
        border: 0 none;
        width: 100%;

        &:before {
          content: attr(data-label);
          position: relative;
          font-weight: bold;
          display: block;
          color: white;
          background-color: var(--table-header-bg);
          padding: 15px;
          margin: -15px -15px 5px -15px;
        }
        &[colspan] {
          &:before {
            content: none;
          }
        }
      }
    }

    .oni-contacts-table {
      thead {
        display: table-header-group;
      }

      tbody tr {
        &:nth-child(2n) {
          background-color: var(--table-bg);
        }
      }

      td {
        border: 1px solid var(--table-border);

        &:before {
          content: none;
        }
      }
    }
  }
}

.rte-footnote,
.rte-disclaimer {
  display: block;
  font-size: 0.75em;
  line-height: 1.5;
}

.rte-disclaimer {
  font-style: italic;
}