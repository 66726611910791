// DISABLED
.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
}

.is-active {
  color: $color-gray-steel !important;
}

.is-hidden,
.is-invalid-msg {
  display: none;
}

.form-field.is-invalid {
  .form-label,
  .form-field-header {
    color: $color-brand-red !important;
  }

  /*
	.input, textarea,
	.select-dropdown-toggle  {
		color: $color-brand-red !important;

		@include placeholder {
		  color: $color-brand-red !important;
		}
	}
	*/

  .input,
  textarea,
  .select-dropdown-toggle {
    border-color: $color-brand-red !important;
  }

  .file-input-container {
    .form-label {
      border-color: $color-brand-red !important;
    }
  }

  .is-invalid-msg {
    display: block;
    color: $color-brand-red;
  }
}

.heading-checkbox.is-invalid {
  .heading-checkbox__label {
    color: $color-brand-red !important;
    font-weight: bold;
  }

  .input {
    color: $color-brand-red !important;

    @include placeholder {
      color: $color-brand-red !important;
    }
  }

  .input {
    border-color: $color-brand-red !important;
  }
}

.is-required {
  &:after {
    content: "*";
    margin-left: 2px;
  }
}

.deceased {
  &:after {
    content: "*";
    color: $color-brand-red;
    margin-left: 2px;
  }
}

.deceased-label {
  color: $color-brand-red;
  position: absolute;
  right: px-to-em(21px);
  bottom: px-to-em(10px);
  font-family: "UniversLTStd-LightCn";
  font-size: 21px;
  font-style: italic;
}

.no-link {
  &:hover {
    //todo: remove any hover effects for a no link
  }
  &:after {
    content: "" !important;
  }
}
