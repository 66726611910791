// Variables
// --------------------------

$ico-font-path: "/assets/site/public/fonts/" !default;
$ico-font-size-base: 14px !default;
$ico-css-prefix: ico !default;
$ico-version: "vj1hxd" !default;
$ico-border-color: #eee !default;
$ico-inverse: #fff !default;
$ico-li-width: 1 !default; //(30em / 14) !default;
$ico-icon-name: "skadden";
