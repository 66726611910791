@import "vars-colors";

///////////////////////////////////////////////////////////////////////////////
//
//  Fonts
//
///////////////////////////////////////////////////////////////////////////////

$font-size-base: 16px;
$font-size-copy: 21px;
$font-size-copy-mobile: 18px;

$font-line-height: 1.5;

//Main Font Univers

$font-family-univers-condensed-oblique: "UniversLTStd-CnObl";
$font-family-univers-condensed-bold-oblique: "UniversLTStd-BoldCnObl";
$font-family-univers-condensed-light: "UniversLTStd-LightCn";
$font-family-univers-condensed-light-oblique: "UniversLTStd-LightCnObl";
$font-family-univers-condensed: "UniversLTStd-Condensed";
$font-family-univers-condensed-bold: "UniversLTStd-CondensedBold";

///////////////////////////////////////////////////////////////////////////////
//
//  File Paths
//
///////////////////////////////////////////////////////////////////////////////

$file-path-images: "/assets/site/public/images/";
$file-path-fonts: "/assets/site/public/fonts/";

///////////////////////////////////////////////////////////////////////////////
//
//  Vertical Rhythm
//
///////////////////////////////////////////////////////////////////////////////

$element-spacing-px: 26px;
$element-spacing-small-px: 9px;

$section-spacing-px: 48px;
$section-spacing-small-px: 17px;
$section-spacing-large-px: 78px;
$section-spacing-largest-px: 130px;

$section-spacing-mobile-px: 35px;
$section-spacing-large-mobile-px: 50px;

///////////////////////////////////////////////////////////////////////////////
//
//  Structure
//
///////////////////////////////////////////////////////////////////////////////

$site-max-width: 1016px;

$content-max-width: 835px;

$site-max-width-wide: 1144px;

$site-padding: 35px;
$site-padding-px: $site-padding;

$site-padding-mobile: 22px;
$site-padding-mobile-px: $site-padding-mobile;

$site-header-height: 61px;
$site-header-fellowshipt-height: 88px;
$site-header-height-mobile: 60px;

$default-column-spacing: 2px;

///////////////////////////////////////////////////////////////////////////////
//
//  Component/Module Variables
//
///////////////////////////////////////////////////////////////////////////////

$site-header-ui-width: 139px; //mobile-toggle and search button
$site-header-ui-width-mobile: 60px; //mobile-toggle and search button

$site-header-ui-font-size: 16px;

$global-line-mark: 54px;
$global-line-thickness: 3px;

$site-searchbox-height: 122px;

//offset to prevent overlap of logo from homepage explore panel
$site-panel-logo-offset: 100px;

$scrollspy-nav-width: 180px;
$scrollspy-content-offset: 240px;

$back-to-top-btn-width: 87px;
$back-to-top-content-offset: 210px;

//listing related
$listing-padding: 28px;
$listing-padding-mobile: 22px;

$arrow-listing-offset: 18px;
$arrow-icon-size: 18px;
$arrow-down-icon-size: 10.5px;
$arrow-padding: 1.31em;

//thumbnail sizes
$professional-thumbnail-height: 162px;
$professional-thumbnail-width: 152px;

//banners
$featured-banner-offset: 21px;
$featured-banner-min-height: 276px;
$featured-banner-tile-width: 290px;

$featured-banner-personnel-min-height: 350px;
$featured-banner-personnel-image-height: 302px;

$featured-banner-tile-width-medium: 240px;
$featured-banner-tile-width-small: 206px;

///////////////////////////////////////////////////////////////////////////////
//
//  Animations
//
///////////////////////////////////////////////////////////////////////////////

$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$transition-timing: 0.35s;
$transition-ease: $easeInOutQuint;

$transition-default: color $transition-timing $transition-ease,
  border-color $transition-timing $transition-ease,
  opacity $transition-timing $transition-ease,
  background-color $transition-timing $transition-ease;

///////////////////////////////////////////////////////////////////////////////
//
//  ZINDEX
//
///////////////////////////////////////////////////////////////////////////////

$zindex-modal-bg: 1000;
$zindex-modal-body: 1005;

$zindex-site-header: 500;
$zindex-site-panel-ui: $zindex-site-header - 50;

$zindex-loading-screen: $zindex-site-header - 10;

$zindex-scrollspy-nav: 100;

$zindex-featured-carousel: 10;
