//  Brandon Mathis Media Queries
///////////////////////////////////////////////////////////////////////////////
//
//	http://codepen.io/imathis/pen/BDzcI
//
//  You should use the Breakpoint Aliases (below) when referencing media query
//  breakpoints with your custom CSS
//
//	Breakpoint Values
//	http://www.paintcodeapp.com/news/iphone-6-screens-demystified
///////////////////////////////////////////////////////////////////////////////

$bp-desktop-large: 1200px;
$bp-desktop: 1025px;
$bp-tablet: 960px;
$bp-mobile: 768px;
$bp-mobile-landscape: 630px;
$bp-mobile-small: 420px;
$bp-landscape-small: 420px;

$bp-bio-desktop: 1125px;

//module specific breakpoints
$bp-scrollspy: 900px;
$bp-back-to-top: 900px;
$bp-featured-banners: 900px;
$bp-featured-carousel-tablet: 860px;

$bp-two-column: 850px;

//Foundation Site Specific CSS
$bp-site-header-fellowship-tablet: 1000px;
$bp-site-footer-fellowship-tablet: 1100px;
$bp-fellowship-carousel-tablet: 1170px;

//maxheight specific
$bp-height-menu-desktop: 600px;

@mixin screen($device-width, $type: max) {
  @if $type== "min" {
    @media screen and (min-width: px-to-em($device-width + 1, $font-size-base)) {
      @content;
    }
  } @else {
    @media screen and (max-width: px-to-em($device-width - 1, $font-size-base)) {
      @content;
    }
  }
}

@mixin screen-landscape($device-height, $type: max) {
  @if $type== "min" {
    @media screen and (min-height: px-to-em($device-height, $font-size-base)) {
      @content;
    }
  } @else {
    @media screen and (max-height: px-to-em($device-height - 1, $font-size-base)) {
      @content;
    }
  }
}

@mixin screen-height($device-height, $type: max) {
  @if $type== "min" {
    @media screen and (min-height: px-to-em($device-height, $font-size-base)) {
      @content;
    }
  } @else {
    @media screen and (max-height: px-to-em($device-height - 1, $font-size-base)) {
      @content;
    }
  }
}
