//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//	Font Size REM/EMS
//
///////////////////////////////////////////////////////////////////////////////

// /**

//   Fonts: Univers

//     Univers condensed
//     Univers condensed oblique
//     Univers condensed bold
//     Univers condensed bold oblique
//     Univers condensed-light
//     Univers condensed-light oblique

//     rte font uses Arial

//  */

// BASE LINE HEIGHT
$font-family-primary: $font-family-univers-condensed;
$font-family-copy: "Arial", sans-serif;

//copy
$font-size-h1: 49px;
$font-size-h2: 45px;
$font-size-h3: 28px;
$font-size-h4: 21px;
$font-size-h5: 17.5px;

$font-size-h1-mobile: 36px;
$font-size-h2-mobile: 32px;
$font-size-h3-mobile: 21px;
$font-size-h4-mobile: 16px;
$font-size-h5-mobile: 14px;

//module font sizes
$section-heading-size: 45px;
$section-heading-mobile-size: 36px;
$panel-section-subheading-size: 38px;
$panel-section-subheading-mobile-size: 30px;

$font-size-heading: $font-size-h1;
$font-size-subheading: 35px;
$font-size-banner: 28px;
$font-size-entity-title: 21px;
$font-size-buttons: 21px;

$font-size-heading-mobile: 36px;
$font-size-subheading-mobile: 30px;
$font-size-banner-mobile: 24px;
$font-size-entity-title-mobile: 18px;
$font-size-buttons-mobile: 18px;

$impact-lead-copy: 28px;
$impact-lead-copy-mobile: 24px;

$font-size-site-header-utility: 16px;

@mixin font-univers-condensed($font-size: null, $base-size: null) {
  font-family: $font-family-univers-condensed;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}
@mixin font-univers-condensed-oblique($font-size: null, $base-size: null) {
  font-family: $font-family-univers-condensed-oblique;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}
@mixin font-univers-condensed-bold($font-size: null, $base-size: null) {
  font-family: $font-family-univers-condensed-bold;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}
@mixin font-univers-condensed-bold-oblique($font-size: null, $base-size: null) {
  font-family: $font-family-univers-condensed-bold-oblique;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}
@mixin font-univers-condensed-light($font-size: null, $base-size: null) {
  font-family: $font-family-univers-condensed-light;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}
@mixin font-univers-condensed-light-oblique(
  $font-size: null,
  $base-size: null
) {
  font-family: $font-family-univers-condensed-light-oblique;

  @if ($font-size != null) {
    @include font-size($font-size, $base-size);
  }
}

//Common heading sizes
@mixin font-size-banner() {
  @include font-univers-condensed-bold($font-size-banner);

  line-height: 1.25;

  em,
  i {
    font-family: $font-family-univers-condensed-bold-oblique;
  }

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-banner-mobile);
  }
}

@mixin font-size-heading() {
  @include font-univers-condensed-bold($font-size-heading);

  line-height: 1.1;

  em,
  i {
    font-family: $font-family-univers-condensed-bold-oblique;
  }

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-heading-mobile);
  }
}

@mixin font-size-subheading() {
  @include font-univers-condensed-bold($font-size-subheading);

  line-height: 1.1;

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-subheading-mobile);
  }
}

@mixin font-size-entity-title() {
  @include font-univers-condensed-bold($font-size-entity-title);

  line-height: 1.2;

  em,
  i {
    font-family: $font-family-univers-condensed-bold-oblique;
  }

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-entity-title-mobile);
  }
}

@mixin font-size-buttons() {
  @include font-univers-condensed-bold($font-size-buttons);

  line-height: 1.1;

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-buttons-mobile);
  }
}

@mixin font-size-copy-text() {
  font-family: $font-family-copy;
  font-size: px-to-em($font-size-copy);

  @include screen($bp-mobile) {
    font-size: px-to-em($font-size-copy-mobile);
  }
}

body {
  font-size: 100%;
  font: normal 100% $font-family-primary, Arial, sans-serif;
  line-height: $font-line-height;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color-brand-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

h1 {
  font-size: px-to-em($font-size-h1);
  line-height: 1.1;
}

h2 {
  font-size: px-to-em($font-size-h2);
  line-height: 1.1;
}

h3 {
  font-size: px-to-em($font-size-h3);
}

h4 {
  font-size: px-to-em($font-size-h4);
}

h5 {
  font-size: px-to-em($font-size-h5);
}

$section-heading-spacing: 28px;

.section-heading {
  @include font-univers-condensed-light($section-heading-size);

  line-height: 1.1;
  margin-bottom: px-to-em($section-heading-spacing, $section-heading-size);
}

.section-heading-spacing {
  @include font-univers-condensed-light($section-heading-size);

  line-height: 1.1;
  margin-bottom: px-to-em($section-heading-spacing * 2, $section-heading-size);
}

.panel-section-subheading,
.rte-subheading {
  display: block;
  @include font-univers-condensed-light($panel-section-subheading-size);
  line-height: 1.1;
  color: $color-text-gray;
  margin-bottom: px-to-em($section-heading-spacing, $section-heading-size);
}
.rte-subheading {
  font-size: 38px;
}

.section-subheading {
  font-size: 1.75em;
  line-height: 1.2;
  margin-bottom: 1em;
  &.small-margin {
    margin-bottom: 0.2em;
    // line-height: 1.5;
  }
}

@include screen($bp-mobile) {
  .section-heading {
    font-size: px-to-em($section-heading-mobile-size);
  }
  .panel-section-subheading {
    font-size: px-to-em($panel-section-subheading-mobile-size);
  }
  .rte-subheading {
    font-size: 30px;
  }
}
