@use "sass:math";

$border-red: 2px solid $color-brand-red;
$border-dark: 1px solid $color-border-dark;

* {
  @include borderBox;

  &:before,
  &:after {
    @include borderBox;
  }
}

figure {
  margin: 0;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;

  @include transition(color);

  &:focus {
    outline: 1px dotted $color-brand-red;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  //margin-bottom:  px-to-em($element-spacing-px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 px-to-em($element-spacing-px);

  &:last-child {
    margin-bottom: 0;
  }
}

address,
em,
i {
  font-style: normal;
}

em,
i {
  @include font-univers-condensed-oblique();
}

strong,
b {
  font-weight: normal;

  @include font-univers-condensed-bold();
}

// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/

#skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;

  &:focus,
  &:active {
    @include transition(background-color);

    height: auto;
    width: auto;
    clip: auto;
    margin: 0;
    display: inline-block;
    overflow: visible;
    z-index: $zindex-site-header + 10;
    background-color: $color-brand-white;
    padding: 0.5em 1em;
  }
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-bottom-3em {
  padding-bottom: 3em !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-top-3em {
  padding-top: 3em !important;
}

.margin-bottom-35px {
  margin-bottom: 35px !important;
}

.mobile-margin-top-0 {
  @include screen($bp-mobile) {
    margin-top: 0 !important;
  }
}

.font-style-italic {
  font-style: italic;
}

:target:before {
  content: "";
  display: block;
  height: px-to-em(math.div($site-header-height, 2));
  margin: px-to-em(-0.5 * $site-header-height) 0 0;
}
