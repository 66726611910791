$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-linkedin: "\f0e1";
$icon-search: "\eb19";
$icon-chevron-bottom: "\f091";
$icon-chevron-left: "\f092";
$icon-chevron-right: "\f093";
$icon-cross: "\eae3";
$icon-minus: "\eb03";
$icon-plus: "\eb0a";
$icon-pdf: "\e902";
$icon-vcard: "\e903";
$icon-share: "\e907";
$icon-chevron-down-alt: "\e904";
$icon-chevron-up: "\e905";
$icon-email: "\e906";
$icon-download: "\e908";
$icon-up-arrow: "\e909";
$icon-location: "\e90b";
$icon-quotation-mark: "\e90a";
$icon-envelope: "\e90c";
$icon-phone: "\e90d";
$icon-close: "\e90e";
$icon-upward-arrow: "\e90f";
$icon-video: "\ea15";
$icon-user: "\e910";
$icon-link: "\e911";
$icon-home: "\e912";
$icon-chevron-down-thin: "\e913";
$icon-chevron-right-thin: "\e914";
$icon-lock: "\e901";
$icon-headphones: "\e915";
