@use "sass:math";

/*========================================
=            Layout Variables            =
========================================*/
$site-padding: px-to-em($site-padding);
$max-width: px-to-em($site-max-width) + ($site-padding * 2);
$max-width-wide: px-to-em($site-max-width-wide) + ($site-padding * 2);

$content-max-width: px-to-em($content-max-width) + ($site-padding * 2);

$site-padding-mobile: px-to-em($site-padding-mobile-px);

//conversion to ems
$section-spacing: px-to-em($section-spacing-px);
$section-spacing-small: px-to-em($section-spacing-small-px);
$section-spacing-large: px-to-em($section-spacing-large-px);
$section-spacing-largest: px-to-em($section-spacing-largest-px);

$section-spacing-mobile: px-to-em($section-spacing-mobile-px);
$section-spacing-large-mobile: px-to-em($section-spacing-large-mobile-px);

$element-spacing: px-to-em($element-spacing-px);
$element-spacing-small: px-to-em($element-spacing-small-px);

//header
$site-header-ui-width: px-to-em($site-header-ui-width);

$site-panel-logo-offset: px-to-em($site-panel-logo-offset);

$section-spacing-mobile: $section-spacing * 0.75;

@mixin page-wrapper {
  max-width: $max-width;
  padding-left: $site-padding;
  padding-right: $site-padding;
  margin-left: auto;
  margin-right: auto;

  @include screen($bp-tablet) {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }
}

@mixin content-wrapper {
  max-width: $content-max-width;
  padding-left: $site-padding;
  padding-right: $site-padding;
  margin-left: auto;
  margin-right: auto;

  @include screen($bp-tablet) {
    padding-left: $site-padding-mobile;
    padding-right: $site-padding-mobile;
  }
}

.page-wrapper {
  @include page-wrapper;

  &--wide {
    max-width: $max-width-wide;
  }
}

.content-wrapper {
  @include content-wrapper;
}

.page-content-wrapper {
  @include content-wrapper;

  padding-top: $section-spacing-large;
  margin-bottom: $section-spacing-largest;
}

//use this if you need top and bottom spacing, but not a wrapper
.page-layout-spacing {
  padding-top: $section-spacing-large;
  margin-bottom: $section-spacing-largest;

  &.reduced-margin {
    margin-bottom: math.div($section-spacing-largest, 2);
  }
}

.no-introduction-spacing {
  padding-top: $section-spacing-large;
}

.top-section-margin {
  margin-top: $section-spacing;
}

.bottom-section-margin {
  margin-bottom: $section-spacing;
}

body {
  //spacing block for fixed site header
  &:before {
    content: "";
    display: block;
    padding-top: px-to-em($site-header-height);
  }
}

@include screen($bp-mobile) {
  body {
    &:before {
      padding-top: px-to-em($site-header-height-mobile);
    }
  }

  .page-content-wrapper,
  .page-layout-spacing {
    padding-top: $section-spacing-large-mobile;
    margin-bottom: $section-spacing-large-mobile;
  }

  .no-introduction-spacing {
    padding-top: $section-spacing-large-mobile;
  }

  .top-section-margin {
    margin-top: $section-spacing-mobile;
  }

  .bottom-section-margin {
    margin-bottom: $section-spacing-mobile;
  }
}
