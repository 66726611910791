@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Icons             =
==================================*/
.ico {
  font-family: "skadden" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.category-tabs-btn, .section-subnav-links-title, .section-nav-menu-link, .page-header-flex-section-title {
  text-transform: uppercase;
}

body {
  font-size: 100%;
  font: normal 100% "UniversLTStd-Condensed", Arial, sans-serif;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #0e0e0e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

h1 {
  font-size: 3.0625em;
  line-height: 1.1;
}

h2 {
  font-size: 2.8125em;
  line-height: 1.1;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.3125em;
}

h5 {
  font-size: 1.09375em;
}

.section-heading, .listing-organization-state-title, .profile-header-compact-name, .article-header-condensed-title {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.8125em;
  line-height: 1.1;
  margin-bottom: 0.6222222222em;
}

.section-heading-spacing {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.8125em;
  line-height: 1.1;
  margin-bottom: 1.2444444444em;
}

.panel-section-subheading,
.rte-subheading {
  display: block;
  font-family: "UniversLTStd-LightCn";
  font-size: 2.375em;
  line-height: 1.1;
  color: #626262;
  margin-bottom: 0.6222222222em;
}

.rte-subheading {
  font-size: 38px;
}

.section-subheading {
  font-size: 1.75em;
  line-height: 1.2;
  margin-bottom: 1em;
}
.section-subheading.small-margin {
  margin-bottom: 0.2em;
}

@media screen and (max-width: 47.9375em) {
  .section-heading, .listing-organization-state-title, .profile-header-compact-name, .article-header-condensed-title {
    font-size: 2.25em;
  }

  .panel-section-subheading {
    font-size: 1.875em;
  }

  .rte-subheading {
    font-size: 30px;
  }
}
.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
}

.is-active {
  color: #767676 !important;
}

.is-hidden,
.is-invalid-msg {
  display: none;
}

.form-field.is-invalid {
  /*
  .input, textarea,
  .select-dropdown-toggle  {
  	color: $color-brand-red !important;

  	@include placeholder {
  	  color: $color-brand-red !important;
  	}
  }
  */
}
.form-field.is-invalid .form-label,
.form-field.is-invalid .form-field-header {
  color: #e82011 !important;
}
.form-field.is-invalid .input,
.form-field.is-invalid textarea,
.form-field.is-invalid .select-dropdown-toggle {
  border-color: #e82011 !important;
}
.form-field.is-invalid .file-input-container .form-label {
  border-color: #e82011 !important;
}
.form-field.is-invalid .is-invalid-msg {
  display: block;
  color: #e82011;
}

.heading-checkbox.is-invalid .heading-checkbox__label {
  color: #e82011 !important;
  font-weight: bold;
}
.heading-checkbox.is-invalid .input {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input::-webkit-input-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input::-moz-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input:-moz-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input:-ms-input-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input {
  border-color: #e82011 !important;
}

.is-required:after {
  content: "*";
  margin-left: 2px;
}

.deceased:after {
  content: "*";
  color: #e82011;
  margin-left: 2px;
}

.deceased-label {
  color: #e82011;
  position: absolute;
  right: 1.3125em;
  bottom: 0.625em;
  font-family: "UniversLTStd-LightCn";
  font-size: 21px;
  font-style: italic;
}

.no-link:after {
  content: "" !important;
}

/*==========================================
=            Utility and States            =
==========================================*/
/*----------  Utilities  ----------*/
.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-reset-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.util-reset-list li {
  margin-bottom: 0;
}

.util-mobile-only {
  display: none;
}
@media screen and (max-width: 47.9375em) {
  .util-mobile-only {
    display: block;
  }
}

@media screen and (max-width: 47.9375em) {
  .util-mobile-hidden {
    display: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .util-mobile-hidden {
    display: none;
  }
  .util-mobile-hidden.hide-important {
    display: none !important;
  }
}

@media screen and (max-width: 59.9375em) {
  .util-tablet-hidden {
    display: none;
  }
  .util-tablet-hidden.hide-important {
    display: none !important;
  }
}

.util-position-relative {
  position: relative;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-invisible-offset::before {
  display: block;
  content: " ";
  margin-top: -150px;
  height: 150px;
  visibility: hidden;
}

.centered {
  text-align: center;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none !important;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

figure {
  margin: 0;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
a:focus {
  outline: 1px dotted #e82011;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 1.625em;
}
p:last-child {
  margin-bottom: 0;
}

address,
em,
i {
  font-style: normal;
}

em,
i {
  font-family: "UniversLTStd-CnObl";
}

strong,
b {
  font-weight: normal;
  font-family: "UniversLTStd-CondensedBold";
}

#skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;
}
#skip-to:focus, #skip-to:active {
  transition: background-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  height: auto;
  width: auto;
  clip: auto;
  margin: 0;
  display: inline-block;
  overflow: visible;
  z-index: 510;
  background-color: white;
  padding: 0.5em 1em;
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-bottom-3em {
  padding-bottom: 3em !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-top-3em {
  padding-top: 3em !important;
}

.margin-bottom-35px {
  margin-bottom: 35px !important;
}

@media screen and (max-width: 47.9375em) {
  .mobile-margin-top-0 {
    margin-top: 0 !important;
  }
}

.font-style-italic {
  font-style: italic;
}

:target:before {
  content: "";
  display: block;
  height: 1.90625em;
  margin: -1.90625em 0 0;
}

/*========================================
=            Layout Variables            =
========================================*/
.page-wrapper {
  max-width: 67.875em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 59.9375em) {
  .page-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}
.page-wrapper--wide {
  max-width: 75.875em;
}

.content-wrapper {
  max-width: 56.5625em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 59.9375em) {
  .content-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}

.page-content-wrapper {
  max-width: 56.5625em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.875em;
  margin-bottom: 8.125em;
}
@media screen and (max-width: 59.9375em) {
  .page-content-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}

.page-layout-spacing {
  padding-top: 4.875em;
  margin-bottom: 8.125em;
}
.page-layout-spacing.reduced-margin {
  margin-bottom: 4.0625em;
}

.no-introduction-spacing {
  padding-top: 4.875em;
}

.top-section-margin {
  margin-top: 3em;
}

.bottom-section-margin {
  margin-bottom: 3em;
}

body:before {
  content: "";
  display: block;
  padding-top: 3.8125em;
}

@media screen and (max-width: 47.9375em) {
  body:before {
    padding-top: 3.75em;
  }

  .page-content-wrapper,
.page-layout-spacing {
    padding-top: 3.125em;
    margin-bottom: 3.125em;
  }

  .no-introduction-spacing {
    padding-top: 3.125em;
  }

  .top-section-margin {
    margin-top: 2.25em;
  }

  .bottom-section-margin {
    margin-bottom: 2.25em;
  }
}
.rte {
  font-family: "Arial", sans-serif;
  font-size: 1.3125em;
}
@media screen and (max-width: 47.9375em) {
  .rte {
    font-size: 1.125em;
  }
}
.rte strong,
.rte b {
  font-family: inherit;
  font-weight: bold;
}
.rte em,
.rte i {
  font-family: inherit;
  font-style: italic;
}
.rte a {
  color: #e82011;
}
.rte a:hover {
  color: #767676;
}
.rte ul,
.rte ol,
.rte p,
.rte img,
.rte blockquote,
.rte address {
  margin-bottom: 1.2380952381em;
}
.rte ul:last-child,
.rte ol:last-child,
.rte p:last-child,
.rte img:last-child,
.rte blockquote:last-child,
.rte address:last-child {
  margin-bottom: 0;
}
.rte img {
  height: auto;
  margin-top: 1.2380952381em;
  max-width: 100%;
}
.rte ul,
.rte ol {
  padding-left: 1.15em;
}
.rte ul {
  list-style: none;
}
.rte ul li {
  position: relative;
}
.rte ul li:before {
  content: "■";
  color: #e82011;
  font-size: 0.6em;
  line-height: 2.25;
  margin-left: -1.75em;
  position: absolute;
  speak: none;
}
.rte ul ul li:before {
  color: #9b9b9b;
}
.rte li {
  margin-bottom: 0.4285714286em;
}
.rte ol {
  list-style-type: decimal;
}
.rte .list-condensed {
  padding-left: 0;
}
.rte .list-condensed li {
  margin-bottom: 0.4285714286em;
}
.rte .list-condensed li:before {
  content: none;
}
.rte h2 {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.1428571429em;
  font-size: 2em;
  margin-bottom: 0.5777777778em;
  margin-top: 1.2em;
}
.rte h2:first-child {
  margin-top: 0;
}
.rte .highlight-link,
.rte .rte-call-to-action {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  padding: 0.6071428571em 0;
  padding-right: 1.31em;
  border: 1px solid #333;
  border-width: 1px 0;
  margin-left: 0;
  position: relative;
}
.rte .highlight-link a,
.rte .rte-call-to-action a {
  color: #0e0e0e;
  display: block;
}
.rte .highlight-link a:after,
.rte .rte-call-to-action a:after {
  content: "\f093";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6428571429em;
  right: 1em;
  color: #ed3124;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.rte .highlight-link a:hover,
.rte .rte-call-to-action a:hover {
  color: #767676;
}
.rte .highlight-link a:hover:after,
.rte .rte-call-to-action a:hover:after {
  color: #767676;
  transform: translateX(100%) translateY(-50%);
}
.rte h3 {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  margin-bottom: 0.9285714286em;
}
.rte blockquote,
.rte .blockquote {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  margin: 0.9285714286em 0;
  margin-left: 1.5em;
  position: relative;
}
.rte blockquote:before,
.rte .blockquote:before {
  content: "\e90a";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 100%;
  margin-right: 0.4em;
  color: #e82011;
}
.rte .blockquote-borders {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  padding: 0.6071428571em 0;
  border: 1px solid #333;
  border-width: 1px 0;
}
.rte .blockquote-borders:before {
  display: none;
}
.rte .subtitle-with-border {
  display: block;
  position: relative;
}
.rte .subtitle-with-border:after {
  content: "";
  display: block;
  width: 2.5714285714em;
  border-top: 3px solid #767676;
  padding-top: 0.619047619em;
  margin-top: 0.619047619em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.rte .video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  max-width: 35em;
}
.rte .video-embed iframe,
.rte .video-embed embed,
.rte .video-embed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.rte iframe,
.rte embed,
.rte object {
  max-width: 100% !important;
}

.rte .rte-field-listing {
  padding-left: 0;
}
.rte .rte-basic-list {
  padding-left: 0;
}
.rte .rte-basic-list li {
  margin-bottom: 0.2em;
}
.rte .rte-basic-list li:before {
  display: none;
}
.rte .rte-copy-size {
  font-size: 1em;
  line-height: 1.5;
  font-family: "Arial", sans-serif;
}

.rte-field-listing {
  padding-left: 0;
}
.rte-field-listing .rte-field-listing-item {
  margin-bottom: 0.4285714286em;
  color: #0e0e0e;
}
.rte-field-listing .rte-field-listing-item:before {
  display: none;
}

.rte-field-listing-label {
  float: left;
  width: 14.2857142857em;
  display: inline-block;
}
.rte-field-listing-label:after {
  content: ":";
}

.rte-field-listing-info {
  display: table;
}

.rte .rte-contact-info {
  padding-left: 0;
}
.rte .rte-contact-info > li {
  color: #0e0e0e;
  margin-bottom: 0.25em;
}
.rte .rte-contact-info > li:before {
  display: none;
}

@media screen and (max-width: 56.1875em) {
  .rte .blockquote-borders {
    margin-left: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .rte {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 39.3125em) {
  .rte-field-listing-label {
    float: none;
    width: auto;
    display: block;
  }

  .rte-field-listing-info {
    display: block;
  }

  .rte a {
    word-wrap: break-word;
    word-break: break-word;
  }
}
.rte-blockquote {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  margin: 0.9285714286em 0;
  margin-left: 1.5em;
  position: relative;
}
.rte-blockquote:before {
  content: "\e90a";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 100%;
  margin-right: 0.4em;
  color: #e82011;
}

.rte-call-to-action {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  padding: 0.6071428571em 0;
  padding-right: 1.31em;
  border: 1px solid #333;
  border-width: 1px 0;
  margin-left: 0;
  position: relative;
  display: block;
}
.rte-call-to-action a {
  color: #0e0e0e;
  display: block;
}
.rte-call-to-action a:after {
  content: "\f093";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6428571429em;
  right: 1em;
  color: #ed3124;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.rte-call-to-action a:hover {
  color: #767676;
}
.rte-call-to-action a:hover:after {
  color: #767676;
  transform: translateX(100%) translateY(-50%);
}

a.rte-call-to-action {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3333333333em;
  line-height: 1.2;
  padding: 0.6071428571em 0;
  padding-right: 1.31em;
  border: 1px solid #333;
  border-width: 1px 0;
  margin-left: 0;
  position: relative;
  display: block;
  color: #0e0e0e;
  display: block;
}
a.rte-call-to-action:after {
  content: "\f093";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6428571429em;
  right: 1em;
  color: #ed3124;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
a.rte-call-to-action:hover {
  color: #767676;
}
a.rte-call-to-action:hover:after {
  color: #767676;
  transform: translateX(100%) translateY(-50%);
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.rte {
  --table-border: #cbcbcb;
  --table-subtitle-bg: #cbcbcb;
  --table-header-bg: #1e1e1d;
  --table-bg: #fff;
  --table-alternate-row: #ededed;
}
.rte .oni-table--dark,
.rte .oni-table-two-column {
  --table-border: #fff;
  --table-header-bg: #1e1e1d;
  --table-bg: #ededed;
}
.rte .oni-table--blue {
  --table-border: #fff;
  --table-header-bg: #124059;
  --table-bg: #ededed;
}
.rte .hide-cell {
  display: none;
}
.rte .oni-table,
.rte .oni-contacts-table {
  margin: 50px 0 20px;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid var(--table-border);
  border-color: var(--table-border);
  background-color: var(--table-bg);
  width: 100%;
  border-spacing: 0;
}
.rte .oni-table th,
.rte .oni-contacts-table th {
  color: #fff;
  background-color: var(--table-header-bg);
  padding: 12px 15px;
  font-size: 15px;
  border: 1px solid var(--table-border);
}
.rte .oni-table td,
.rte .oni-contacts-table td {
  padding: 12px 15px;
  border: 1px solid var(--table-border);
  font-size: 14px;
}
.rte .oni-table td[colspan],
.rte .oni-contacts-table td[colspan] {
  background-color: var(--table-subtitle-bg);
}
.rte .oni-table-two-column {
  margin: 50px 0 20px;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid var(--table-border);
  border-color: var(--table-border);
  background-color: var(--table-bg);
  width: 100%;
  border-spacing: 0;
}
.rte .oni-table-two-column th {
  color: #fff;
  background-color: var(--table-header-bg);
  padding: 12px 15px;
  font-size: 15px;
  border: 1px solid var(--table-border);
}
.rte .oni-table-two-column td {
  padding: 12px 15px;
  border: 1px solid var(--table-border);
  font-size: 14px;
}
.rte .oni-table-two-column td[colspan] {
  background-color: var(--table-subtitle-bg);
}
@media screen and (max-width: 59.9375em) {
  .rte .oni-table,
.rte .oni-contacts-table {
    background-color: #fff;
    border: 1px solid var(--table-alternate-row);
  }
  .rte .oni-table thead,
.rte .oni-contacts-table thead {
    display: none;
  }
  .rte .oni-table tbody,
.rte .oni-contacts-table tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .rte .oni-table tbody tr:nth-child(2n),
.rte .oni-contacts-table tbody tr:nth-child(2n) {
    background-color: var(--table-alternate-row);
  }
  .rte .oni-table tbody tr,
.rte .oni-contacts-table tbody tr {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
  }
  .rte .oni-table td,
.rte .oni-contacts-table td {
    border: 0 none;
    width: 100%;
  }
  .rte .oni-table td:before,
.rte .oni-contacts-table td:before {
    content: attr(data-label);
    position: relative;
    font-weight: bold;
    display: block;
    color: white;
    background-color: var(--table-header-bg);
    padding: 15px;
    margin: -15px -15px 5px -15px;
  }
  .rte .oni-table td[colspan]:before,
.rte .oni-contacts-table td[colspan]:before {
    content: none;
  }
  .rte .oni-contacts-table thead {
    display: table-header-group;
  }
  .rte .oni-contacts-table tbody tr:nth-child(2n) {
    background-color: var(--table-bg);
  }
  .rte .oni-contacts-table td {
    border: 1px solid var(--table-border);
  }
  .rte .oni-contacts-table td:before {
    content: none;
  }
}

.rte-footnote,
.rte-disclaimer {
  display: block;
  font-size: 0.75em;
  line-height: 1.5;
}

.rte-disclaimer {
  font-style: italic;
}

.field-mapping {
  font-family: "Arial", sans-serif;
  font-size: 1.3125em;
}
@media screen and (max-width: 47.9375em) {
  .field-mapping {
    font-size: 1.125em;
  }
}

.field-mapping-label {
  float: left;
  width: 12.380952381em;
  display: inline-block;
  font-weight: bold;
}
.field-mapping-label:after {
  content: ":";
}

.field-mapping-info {
  display: table;
  margin-left: 0;
  margin-bottom: 0.4285714286em;
}
.field-mapping-info a {
  color: #ed3124;
}
.field-mapping-info a:hover {
  color: #767676;
}
.field-mapping-info:after {
  content: "\a";
  white-space: pre;
}

@media screen and (max-width: 39.3125em) {
  .field-mapping-label {
    float: none;
    width: auto;
    display: block;
  }

  .rte-field-listing-info {
    display: block;
  }
}
.site-footer, .site-search-panel, .category-tabs-label, .sponsoring-organizations-filters, .page-foundation-forum-detail .section-nav-menu,
.page-foundation-webinar-detail .section-nav-menu,
.page-foundation-facts-law-schools .section-nav-menu,
.page-foundation-facts-sponsor-orgs .section-nav-menu,
.page-foundation-facts-governance .section-nav-menu,
.page-foundation-generic-content .section-nav-menu, .keyword-search,
.alpha-search,
.exposed-search-filters,
.advanced-search,
.loading-screen,
.applied-filter-clear, .page-header-user-actions, .site-header--fellowship .site-header-utility,
.site-header--fellowship .site-user-login-label, .foundation-explore, .twitter-feed,
.social-links,
.back-to-top-trigger,
.suggested-insight,
.languages,
.scrollspy-nav,
.capabilities-children-related-entit,
.btn-text-load-more,
.sr-only,
.call-to-action,
.job-body-related-video, .listing-articles-subscribe, .listing-articles-see-all, .read-more-toggle,
.overview-cta, .personnel-spotlight-view-more, .profile-header-linkedin, .impactful-lead-cross-links, .section-nav-toggle, .site-search-toggle,
.mobile-toggle,
.site-nav,
.site-header-firm-name {
  display: none !important;
}

body {
  font-size: 10pt;
}
body:before {
  content: none;
}

button,
.btn {
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.35s cubic-bezier(0.86, 0, 0.07, 1), background-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.page-wrapper,
.content-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.scrollspy-sections .js-scrollspy-section {
  margin-bottom: 4.875em;
}

.related-capabilities,
.insights-grid,
.capabilities-children,
.flex-panel,
.personnel-spotlight,
.overview {
  margin-bottom: 4.875em;
}

.accordion-node {
  margin-bottom: 3em;
}

.rte {
  margin-bottom: 1.625em;
}

.site-logo {
  width: 15.625em;
  display: inline-block;
  color: white;
  background-color: #e82011;
}

.site-logo-img {
  display: block;
  width: 100%;
}

.breadcrumbs {
  padding: 1.0625em 0 3em;
}

.breadcrumbs-item {
  display: inline;
}

.breadcrumbs-title {
  color: #5f5f5f;
  font-family: "UniversLTStd-CondensedBold";
}

.site-header-utility {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1em;
  line-height: 1.2;
}

.page-header,
.page-header-flex {
  padding-bottom: 4.875em;
}

.page-header-title,
.page-header-flex-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 3.4375em;
  line-height: 1.1;
}

.page-header-flex-section-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  padding-bottom: 0.619047619em;
  margin-bottom: 1.0476190476em;
  border-bottom: 1px solid #d2d2d2;
}
.page-header-flex-section-title em,
.page-header-flex-section-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .page-header-flex-section-title {
    font-size: 1.125em;
  }
}

.section-nav {
  display: inline-block;
}
.section-nav + .section-subnav {
  display: inline-block;
}
.section-nav + .section-subnav:before {
  content: "/";
  color: #767676;
  margin: 0 1em;
}
.section-nav + .section-subnav .section-subnav-wrapper {
  display: inline-block;
}

.section-nav-menu-link {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.25em;
  display: none;
}
.is-menu-active .section-nav-menu-link {
  display: block;
}

.section-subnav-links-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  display: none;
}
.section-subnav-links-title em,
.section-subnav-links-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .section-subnav-links-title {
    font-size: 1.125em;
  }
}
.is-submenu-active .section-subnav-links-title {
  display: block;
}

.impactful-lead {
  padding: 3em 0;
}

.impactful-lead-copy {
  font-size: 1.75em;
  line-height: 1.2;
  padding: 0.7857142857em 0;
  border-top: 2px solid 2px solid #e82011;
  border-bottom: 2px solid 2px solid #e82011;
}

.article-header-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 3.0625em;
  line-height: 1.1;
}
.article-header-title em,
.article-header-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .article-header-title {
    font-size: 2.25em;
  }
}

.article-header-meta {
  font-size: 1.75em;
  position: relative;
}
.article-header-meta:after {
  content: "";
  display: block;
  width: 1.9285714286em;
  border-top: 3px solid #d9d9d9;
  padding-top: 0.6071428571em;
  margin-top: 0.6071428571em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.article-header-slogan {
  font-family: "UniversLTStd-LightCnObl";
  font-size: 1.75em;
  font-size: 1.3125em;
  line-height: 1.2;
}

.article-header-authors {
  font-size: 1.3125em;
}

.article-body {
  margin-bottom: 4.875em;
}

.capabilities-list-capability {
  display: inline;
  font-size: 1.3125em;
  line-height: 1.4;
}
.capabilities-list-capability:after {
  content: " // ";
  display: inline-block;
  margin: 0 0.2em;
}
.capabilities-list-capability:last-child:after {
  display: none;
}

.profile-header {
  position: relative;
}

.profile-header-wrapper {
  height: 25em;
  display: table;
}

.profile-header-figure,
.profile-header-details {
  display: table-cell;
  vertical-align: top;
}

.profile-header-snapshot-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 21.875em;
}

.profile-header-snapshot {
  height: 25em;
  position: relative;
  margin-left: -3em;
}

.profile-header-details {
  position: relative;
  z-index: 5;
  padding-right: 1em;
  padding-left: 1em;
  background-color: white;
  height: 100%;
}

.profile-header-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 3.0625em;
  line-height: 1.1;
}
.profile-header-name em,
.profile-header-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .profile-header-name {
    font-size: 2.25em;
  }
}

.profile-header-position {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.75em;
  position: relative;
}
.profile-header-position:after {
  content: "";
  display: block;
  width: 1.9285714286em;
  border-top: 3px solid #d9d9d9;
  padding-top: 0.625em;
  margin-top: 0.625em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.profile-header-email {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.3125em;
}

.offices-related {
  margin-top: 0.5em;
  overflow: hidden;
}

.offices-related-office {
  width: 12.5em;
  padding-right: 0.9em;
  padding-bottom: 1em;
  display: inline-block;
  vertical-align: top;
}

.offices-related-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  line-height: 1.3;
  text-transform: uppercase;
}
.offices-related-name em,
.offices-related-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .offices-related-name {
    font-size: 1.125em;
  }
}

.offices-related-phone {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.25em;
  line-height: 1.3em;
  text-transform: uppercase;
}

.get-in-touch .offices-related-office {
  margin-top: 0.65em;
}

.get-in-touch-header {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 3.0625em;
  line-height: 1.1;
  line-height: 1.2;
}
.get-in-touch-header em,
.get-in-touch-header i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .get-in-touch-header {
    font-size: 2.25em;
  }
}

.get-in-touch-position {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.75em;
  line-height: 1.2;
  position: relative;
}
.get-in-touch-position:after {
  content: "";
  display: block;
  width: 1.9285714286em;
  border-top: 3px solid #e82011;
  padding-top: 0.625em;
  margin-top: 0.625em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.get-in-touch-email {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.3125em;
}

.personnel-banner,
.highlight-card {
  overflow: hidden;
  display: block;
}
.personnel-banner + .personnel-banner,
.personnel-banner + .highlight-card,
.highlight-card + .personnel-banner,
.highlight-card + .highlight-card {
  margin-top: 3em;
}

.personnel-banner-figure,
.highlight-card-figure {
  width: 11.25em;
  float: left;
}

.personnel-banner-img,
.highlight-card-img {
  max-width: 100%;
}

.personnel-banner-info,
.highlight-card-link {
  margin-left: 12.5em;
  display: block;
}

.personnel-banner-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
}
.personnel-banner-name em,
.personnel-banner-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .personnel-banner-name {
    font-size: 1.5em;
  }
}

.personnel-banner-subtitle {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.3125em;
  line-height: 1.2;
}

.personnel-banner-details {
  position: relative;
}
.personnel-banner-details:after {
  content: "";
  display: block;
  width: 3.375em;
  border-top: 3px solid #e82011;
  padding-top: 1.375em;
  margin-top: 1.375em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.personnel-banner-details:after {
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.highlight-card-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
  padding-bottom: 0.9285714286em;
  position: relative;
}
.highlight-card-title em,
.highlight-card-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .highlight-card-title {
    font-size: 1.5em;
  }
}
.highlight-card-title:before {
  content: "";
  display: block;
  width: 1.9285714286em;
  border-top: 3px solid #e82011;
  padding-top: 0.4642857143em;
  margin-top: 0.4642857143em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.highlight-card-title:before {
  position: absolute;
  bottom: 0;
}

.accordion-node-title {
  font-size: 1em;
}

.accordion-node-trigger {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
  text-align: left;
}
.accordion-node-trigger em,
.accordion-node-trigger i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .accordion-node-trigger {
    font-size: 1.5em;
  }
}

.accordion-node-content {
  display: block !important;
}

.listing-articles + .listing-articles {
  margin-top: 4.875em;
}
.listing-articles .ng-scope {
  display: block;
}
.listing-articles .ng-scope + .ng-scope {
  padding-top: 1.75em;
}

.listing-articles-heading {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.1875em;
  line-height: 1.1em;
  position: relative;
}
.listing-articles-heading:after {
  content: "";
  display: block;
  width: 1.5428571429em;
  border-top: 3px solid #d9d9d9;
  padding-top: 0.4em;
  margin-top: 0.4em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.listing-articles-item,
.insights-grid-list-item {
  position: relative;
  padding-bottom: 1.75em;
  border-bottom: 1px solid #333;
}
.listing-articles-item:after,
.insights-grid-list-item:after {
  content: "";
  display: table;
  clear: both;
}
.listing-articles-item + .listing-articles-item,
.listing-articles-item + .insights-grid-list-item,
.insights-grid-list-item + .listing-articles-item,
.insights-grid-list-item + .insights-grid-list-item {
  padding-top: 1.75em;
}

.listing-articles-item--video {
  padding-left: 2.5em;
}

.listing-articles-title-nolink {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
  display: block;
}
.listing-articles-title-nolink em,
.listing-articles-title-nolink i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .listing-articles-title-nolink {
    font-size: 1.5em;
  }
}

.listing-articles-title,
.insight-tile {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
  display: block;
}
.listing-articles-title em,
.listing-articles-title i,
.insight-tile em,
.insight-tile i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .listing-articles-title,
.insight-tile {
    font-size: 1.5em;
  }
}

.listing-articles-item--video .listing-articles-title:before {
  content: "\ea15";
  display: inline-block;
  font-family: "skadden";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 0.9285714286em;
  left: 0;
  margin-top: 0.2em;
}

.listing-articles-meta {
  font-size: 1.3125em;
  line-height: 1.2;
  margin-top: 0.4047619048em;
  color: #767676;
}
.listing-articles-meta .meta-category + .meta-date:before {
  content: "//";
  display: inline-block;
  margin-right: 0.5em;
  margin-left: 0.25em;
}
.listing-articles-meta .meta-location + .meta-date:before {
  content: "//";
  display: inline-block;
  margin-right: 0.5em;
  margin-left: 0.25em;
}

.listing-articles-abstract {
  margin-top: 0.4047619048em;
}
.listing-articles-abstract + .listing-articles-meta {
  margin-top: 0.8095238095em;
}

.listing-articles-figure {
  float: left;
  max-width: 20.625em;
  margin-right: 1em;
}
.listing-articles-figure > img {
  max-width: 100%;
}

.listing-articles-title-label {
  display: table;
  font-size: inherit;
}

.listing-articles-item--alumni-move {
  padding-left: 3.8125em;
}

.listing-articles-alumni-year {
  transition: background-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  position: absolute;
  left: 0;
  background: #e82011;
  font-size: 0.9285714286em;
  line-height: 1;
  padding-top: 0.45em;
  padding-right: 0.2em;
  width: 1.8461538462em;
  height: 1.8461538462em;
  text-align: center;
  display: inline-block;
  color: white;
  margin-top: 0.25em;
}
.listing-articles-alumni-year:before {
  content: "’";
}

.listing-articles-alumni-facts {
  font-size: 1.3125em;
  line-height: 1.2;
  margin-top: 0.4047619048em;
  display: table;
}
.listing-articles-alumni-facts a {
  color: #ed3124;
}
.listing-articles-alumni-facts a:hover {
  color: #767676;
}
.listing-articles-alumni-facts a:focus {
  outline: 1px dotted #e82011;
}
.listing-articles-alumni-facts .meta-facts {
  color: #767676;
}
.listing-articles-alumni-facts .meta-facts + .meta-summary:before {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
  margin-left: 0.25em;
}
.listing-articles-alumni-facts .meta-summary {
  display: inline;
}

.listing-articles-item--alumni-spotlight .listing-articles-figure {
  max-width: 6.25em;
}

.professional-card {
  display: table;
  width: 100%;
  min-height: 10.125em;
}

.professional-card-figure,
.professional-card-details {
  display: table-cell;
  vertical-align: top;
}

.professional-card-figure {
  position: relative;
  overflow: hidden;
  width: 8.5em;
  max-width: 8.5em;
}
.professional-card-figure > img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  min-height: 100%;
}

.professional-card-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
}
.professional-card-name em,
.professional-card-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .professional-card-name {
    font-size: 1.125em;
  }
}

.professional-card-details {
  padding: 1.2em 1.5em 0.4em;
  text-align: left;
  position: relative;
}
.professional-card-details:after {
  content: "";
  display: block;
  width: 3.375em;
  border-top: 3px solid #e82011;
  padding-top: 1.09375em;
  margin-top: 1.09375em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.professional-card-details:after {
  padding-top: 0;
}

.professional-card-position {
  font-size: 1.3125em;
  line-height: 1.2;
}

.related-listing-capabilities-link {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  font-size: 1.375em;
  line-height: 1.2;
  display: block;
  margin-bottom: 1em;
}
.related-listing-capabilities-link em,
.related-listing-capabilities-link i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .related-listing-capabilities-link {
    font-size: 1.125em;
  }
}

.listing-files-option {
  display: inline;
}

.listing-files-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  display: inline-block;
  padding: 0.8em 1em 0.65em;
  margin-right: 0.25em;
  margin-bottom: 0.4em;
  border: 1px solid #767676;
}
.listing-files-title em,
.listing-files-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .listing-files-title {
    font-size: 1.125em;
  }
}

.credential-item-heading {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.5em;
  line-height: 1.1em;
  position: relative;
}
.credential-item-heading:after {
  content: "";
  display: block;
  width: 2.25em;
  border-top: 3px solid #d9d9d9;
  padding-top: 0.625em;
  margin-top: 0.625em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.credential-item-heading:after {
  margin-top: 0.65em;
}

.site-header--fellowship {
  margin-bottom: 3em;
}
.site-header--fellowship .site-logo {
  width: 18.75em;
  padding: 1.25em 1.375em;
}

.page-header--fellowship {
  background-image: none !important;
}

.article-header-condensed-details {
  position: relative;
}
.article-header-condensed-details:after {
  content: "";
  display: block;
  width: 3.375em;
  border-top: 3px solid #d9d9d9;
  padding-top: 1.375em;
  margin-top: 1.375em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.article-header-condensed-title {
  margin-bottom: 0;
}

.article-header-condensed-meta {
  font-size: 1.75em;
  margin-top: 0.5625em;
}

.content-lead-copy {
  font-size: 1.75em;
  line-height: 1.2;
  padding-bottom: 1.3928571429em;
  border-bottom: 1px solid #333;
  margin-bottom: 1.3928571429em;
}

.page-content-wrapper {
  padding: 0;
}

.profile-header-compact {
  overflow: hidden;
}

.profile-header-compact-position {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.1875em;
  position: relative;
  line-break: 1.2;
}
.profile-header-compact-position:after {
  content: "";
  display: block;
  width: 1.5428571429em;
  border-top: 3px solid #767676;
  padding-top: 0.5em;
  margin-top: 0.5em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.profile-header-compact-employment {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.3125em;
  line-break: 1.2;
}

.profile-header-compact-figure {
  float: left;
}
.profile-header-compact-figure + .profile-header-compact-details {
  margin-left: 14.375em;
}

.profile-header-compact--fellows {
  margin-bottom: 4.875em;
}
.profile-header-compact--fellows .profile-header-compact-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
}
.profile-header-compact--fellows .profile-header-compact-name em,
.profile-header-compact--fellows .profile-header-compact-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .profile-header-compact--fellows .profile-header-compact-name {
    font-size: 1.5em;
  }
}
.profile-header-compact--fellows .profile-header-compact-position {
  font-size: 21px;
}
.profile-header-compact--fellows .profile-header-compact-position:after {
  padding-top: 0.8333333333em;
  margin-top: 0.8333333333em;
  width: 2.5714285714em;
}

.job-header-compact {
  position: relative;
}
.job-header-compact:after {
  content: "";
  display: block;
  width: 3.375em;
  border-top: 3px solid #d9d9d9;
  padding-top: 1.0625em;
  margin-top: 1.0625em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.job-header-compact-meta {
  font-size: 1.75em;
}
.job-header-compact-meta .meta-item + .meta-item:before {
  content: "//";
  display: inline-block;
  margin: 0 0.25em;
}

.job-header-compact-related-item {
  font-size: 1.3125em;
}

.job-header-compact-related-label {
  color: #767676;
}
.job-header-compact-related-label:after {
  content: ":";
  margin-right: 0.5em;
}

.listing-general-entity-item {
  position: relative;
  padding-bottom: 1.75em;
  border-bottom: 1px solid #333;
}
.listing-general-entity-item:after {
  content: "";
  display: table;
  clear: both;
}
.listing-general-entity-item + .listing-general-entity-item {
  padding-top: 1.75em;
}

.listing-general-entity-title {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  line-height: 1.2;
  display: block;
}
.listing-general-entity-title em,
.listing-general-entity-title i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .listing-general-entity-title {
    font-size: 1.125em;
  }
}

.listing-organization-state {
  position: relative;
  padding-bottom: 1.75em;
}
.listing-organization-state + .listing-organization-state {
  padding-top: 1.75em;
}

.listing-organization-city + .listing-organization-city {
  padding-top: 1.75em;
}

.listing-organization-city-title {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.1875em;
  line-height: 1.1em;
  color: #767676;
  position: relative;
}
.listing-organization-city-title:after {
  content: "";
  display: block;
  width: 1.5428571429em;
  border-top: 3px solid #d9d9d9;
  padding-top: 0.4em;
  margin-top: 0.4em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.listing-organization-sponsors-list {
  font-family: "Arial", sans-serif;
  font-size: 1.3125em;
}

.listing-organization-sponsor-img {
  max-height: 1.4375em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.listing-organization-sponsor {
  position: relative;
  padding-left: 1.15em;
}
.listing-organization-sponsor:before {
  content: "■";
  color: #ed3124;
  font-size: 0.6em;
  line-height: 2.25;
  margin-left: -1.75em;
  position: absolute;
}

.governance-listing {
  margin-bottom: 4.875em;
}

.governance-listing-cards .professional-card {
  margin-bottom: 1.75em;
}

.category-tabs-btn {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.3125em;
  margin-bottom: 1.0625em;
  display: block;
}

.category-tabs-item {
  display: none;
}
.category-tabs-item.is-tab-active {
  display: block;
}

.fellows-card {
  position: relative;
  margin-bottom: 3em;
}
.fellows-card:after {
  content: "";
  display: table;
  clear: both;
}

/*----------  name and partner related info  ----------*/
.fellows-card-figure {
  float: left;
  width: 12.625em;
  position: relative;
  margin-right: 5%;
  overflow: hidden;
}
.fellows-card-figure img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}
.fellows-card-figure + .fellows-card-details {
  display: table;
}

.fellows-card-name {
  font-family: "UniversLTStd-CondensedBold";
  font-size: 1.75em;
  line-height: 1.25;
  padding-right: 2em;
}
.fellows-card-name em,
.fellows-card-name i {
  font-family: "UniversLTStd-BoldCnObl";
}
@media screen and (max-width: 47.9375em) {
  .fellows-card-name {
    font-size: 1.5em;
  }
}

.fellows-card-class-year {
  display: inline-block;
  font-size: 0.6071428571em;
  padding: 0.25em 0.5em 0.18em;
}

.fellows-card-description {
  position: relative;
}
.fellows-card-description:before {
  content: "";
  display: block;
  width: 3.375em;
  border-top: 3px solid #e82011;
  padding-top: 1.375em;
  margin-top: 1.375em;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}

.fellows-card-credentials-item {
  font-family: "UniversLTStd-LightCn";
  font-size: 1.3125em;
  line-height: 1.2;
  margin-top: 0.3em;
}