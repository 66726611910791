// colors by name, transparency
$color-nero: rgba(14, 14, 14, 1);
$color-nero-transparent: rgba(14, 14, 14, 0);
$color-nero-10: rgba(14, 14, 14, 0.1);
$color-nero-25: rgba(14, 14, 14, 0.25);
$color-nero-30: rgba(14, 14, 14, 0.3);
$color-nero-60: rgba(14, 14, 14, 0.6);
$color-nero-90: rgba(14, 14, 14, 0.9);
$color-nero-98: rgba(14, 14, 14, 0.98);

$color-white-full: rgba(14, 14, 14, 1);
$color-white-10: rgba(14, 14, 14, 0.1);

$color-gainsboro-65: rgba(#d9d9d9, 0.65);

$color-black: rgba(0, 0, 0, 1);
$color-black-transparent: rgba(0, 0, 0, 0);
$color-black-30: rgba(0, 0, 0, 0.3);
$color-black-50: rgba(0, 0, 0, 0.5);

$color-white-transparent: rgba(255, 255, 255, 0);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-white-10: rgba(255, 255, 255, 0.1);

//Brand colors
$color-brand-black: rgb(14, 14, 14);
$color-brand-white: rgba(255, 255, 255, 1);
$color-brand-gray: #d9d9d9;
$color-brand-turquoise: #2bb3d5;
$color-brand-dark: #1b1b1b;

// Adjusted brand colors for accessibility
$color-brand-red: #e82011;
$color-alizarin-dark: #db4535;

$color-charcoal: #333;

$color-gray-light: #f9f9f9;
$color-gray-steel: #767676;
$color-gray-ac: #5f5f5f; //proper accessibility contrast
$color-gray-alt: #d2d2d2;
$color-gray-med: #ededed;
$color-grey-f6: #f6f6f6;
$color-gray-timeline: #c6c6c6;

//opaque
$color-opaque-red: rgba(184, 48, 37, 0.9);

//text colors
$color-text-base: $color-brand-black;
$color-text-gray: #626262;
$color-text-gray-alt: #4f4f4f;
$color-text-gray-soft: #9b9b9b;

$color-text-body: $color-brand-black;
$color-text-red: #ed3124;
$color-text-red-hover: #f1c4c4;

//context color assignments
$color-link: $color-text-red;
$color-link-hover: $color-gray-steel;

//component colors
$color-border: #a4a4a4;
$color-border-dark: $color-charcoal;
$color-featured-banner: $color-gray-med;
$color-search-filters: $color-gray-med;
$color-site-header-border: #979797;

//gradient start
$color-gradiant-gray-start: #eaeaea;
$color-gradiant-gray-end: rgba(184, 48, 37, 0);

$color-gradiant-dark-start: #131313;
$color-gradiant-dark-end: rgba(103, 103, 103, 0);

// colors by contrast
$color-black-background: $color-brand-black;
$color-white-background: $color-brand-white;
$color-lightgray-background: $color-gray-light;

$color-gray-on-gray-foreground: $color-gray-ac;
$color-gray-on-gray-background: $color-brand-gray;

$color-red-on-white-foreground: $color-brand-red;

$color-red-on-black-foreground: #ee2517;

$color-white-on-red-foreground: $color-brand-white;
$color-red-background: $color-brand-red;

$color-red-on-lightgray-foreground: #da1e10;

$color-gray-on-lightgray-foreground: #6e6e6e;

$color-brand-red-on-medgray-foreground: #d11d10;
